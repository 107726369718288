import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useAuthModalContext } from '@context/AuthModalContext/AuthModalContext';
import { AUTH_MODAL_TYPES } from '@commons/auth';

export const LoginPage = () => {
  const router = useRouter();
  const authModalContext = useAuthModalContext();
  const { mfaToken } = router.query;
  const { openAuthModal, state } = authModalContext;
  const { SOCIAL_SIGN_IN, SIGN_IN } = AUTH_MODAL_TYPES;

  useEffect(() => {
    if (state.currentModal === SOCIAL_SIGN_IN && !state.isSocialsModal) {
      openAuthModal(mfaToken ? SIGN_IN : SOCIAL_SIGN_IN);
    }
  }, [state, mfaToken]);

  return <></>;
};
